import {Create, RecordContextProvider, SelectInput, SimpleForm, useDataProvider} from 'react-admin';
import {Link} from "react-router-dom";
import {Box, Button, Card, Grid, SxProps, Typography} from "@mui/material";
import React, {useState} from "react";
import {SessionStatus} from "../../../components/provider/SessionStatus";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import {useFormContext} from "react-hook-form";
import {PrettyDatetime} from "../../../components/common/PrettyDatetime";
import {OperationHistory} from "../../../components/common/OperationHistory";

const TargetDevices = [
	{label: "VisionRTK(8の字)",},
	{label: "LiDAR(四角)",},
];

interface formData {
	target_device: string | undefined,
}

const sxButton: SxProps = {
	minWidth: 90,
	height: 90,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}

const sxCaption1: SxProps = {
	fontSize: "1.0em",
	height: "30px",
}

const CardCell = (props: { caption: string, content: string }) => {
	return (
		<Box sx={{padding: 1.5, border: "1px solid #ccc", borderRadius: 2}}>
			<Typography variant="caption" gutterBottom sx={{color: "#666"}}>
				{props.caption}
			</Typography>
			<Typography variant="body1" sx={{fontSize: "1.4rem"}}>
				{props.content}
			</Typography>
		</Box>
	);
}

const FormButton = (props: { disabled: boolean, onClick: (form: formData) => void, children: any }) => {
	const ctx = useFormContext();

	const handleClick = () => {
		const form = ctx.getValues() as formData;
		props.onClick(form);
	}

	return (
		<Button variant="contained" sx={sxButton} onClick={handleClick} disabled={props.disabled}>
			{props.children}
		</Button>
	);
}

export const DriveCalibrationLogPage = () => {
	const dataProvider = useDataProvider();
	const [isButtonsDisabled, setIsButtonsDisabled] = useState<boolean>(false);
	const [isInputFilled, setIsInputFilled] = useState<boolean>(false);
	const [textLastReported, setTextLastReported] = useState<string>("-");

	// セッション確認
	const current_driver = SessionStatus.getDriver();
	const current_vehicle = SessionStatus.getVehicle();
	const isSelected = current_driver.selected && current_vehicle.selected;

	const handleSelectChange = (event: any) => {
		setIsInputFilled(event.target.value !== "");
	}

	// クリックイベント: 走行開始
	const handleReportStarted = (form: formData) => {
		if (!window.confirm("キャリブレーション走行を開始します。よろしいですか？")) {
			return false;
		}

		setIsButtonsDisabled(true);
		dataProvider.create("calibration_log", {
			data: {
				is_start: true,
				vehicle: current_vehicle.id,
				driver: current_driver.id,
				target_device: form.target_device,
			}
		}).then((res: any) => {
			const resData = res.data.data;
			setIsButtonsDisabled(false);
			setTextLastReported("【開始】 " + PrettyDatetime.to_japanese_datetime((new Date(resData.timestamp)).getTime()));
			OperationHistory.appendRow({
				type: "calibration",
				id: res.data.id,
				timestamp: resData.timestamp,
				is_start: true,
				vehicle_id: resData.vehicle_id,
				vehicle_name: resData.vehicle_name,
				driver_id: resData.driver_id,
				driver_name: resData.driver_name,
				target_device: resData.target_device,
			});
		}).catch((error) => {
			console.log("Failed: ", error);
			setIsButtonsDisabled(false);
		});
	}

	// クリックイベント: 走行終了
	const handleReportFinished = (form: formData) => {
		if (!window.confirm("キャリブレーション走行を終了します。よろしいですか？")) {
			return false;
		}

		setIsButtonsDisabled(true);
		dataProvider.create("calibration_log", {
			data: {
				is_start: false,
				vehicle: current_vehicle.id,
				driver: current_driver.id,
				target_device: form.target_device,
			}
		}).then((res: any) => {
			const resData = res.data.data;
			setIsButtonsDisabled(false);
			setTextLastReported("【終了】 " + PrettyDatetime.to_japanese_datetime((new Date(resData.timestamp)).getTime()));
			OperationHistory.appendRow({
				type: "calibration",
				id: res.data.id,
				timestamp: resData.timestamp,
				is_start: false,
				vehicle_id: resData.vehicle_id,
				vehicle_name: resData.vehicle_name,
				driver_id: resData.driver_id,
				driver_name: resData.driver_name,
				target_device: resData.target_device,
			});
		}).catch((error) => {
			console.log("Failed: ", error);
			setIsButtonsDisabled(false);
		});
	}

	return (
		<RecordContextProvider>
			<Typography sx={{
				textAlign: "center",
				mt: 2,
				mb: 1,
				fontSize: "1.6rem",
			}}>
				キャリブレーション走行
			</Typography>
			<Box sx={{padding: 2}}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<CardCell caption="ドライバー" content={current_driver.display_name}/>
					</Grid>

					<Grid item xs={4}>
						<CardCell caption="車両番号" content={current_vehicle.id || "-"}/>
					</Grid>
					<Grid item xs={8}>
						<CardCell caption="車両名" content={current_vehicle.name || "-"}/>
					</Grid>
				</Grid>
			</Box>
			<Box sx={{padding: 2}}>
				<Create resource="error_report">
					<SimpleForm id="post_create_form" toolbar={false}>
						<SelectInput
							source="target_device"
							name="target_device"
							label="対象デバイス"
							choices={TargetDevices}
							optionText="label"
							optionValue="label"
							sx={{maxWidth: '100%', mb: -2}}
							onChange={handleSelectChange}
							disabled={isButtonsDisabled}
						/>
						<Card sx={{width: "100%", padding: 1}}>
							<Typography variant="caption" color="text.secondary">最終報告時刻</Typography>
							<Typography variant="body1">{textLastReported}</Typography>
						</Card>
						<Grid container spacing={2} sx={{mt: 5}}>
							<Box
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								sx={{width: '80%', margin: '0 auto', padding: 1}}
							>
								<Box display="flex" flexDirection="column" alignItems="center">
									<FormButton onClick={handleReportStarted}
												disabled={isButtonsDisabled || !isSelected || !isInputFilled}>
										<PlayCircleIcon fontSize="large"/>
									</FormButton>
									<Typography variant="body2" mt={1} sx={sxCaption1}>
										開始報告
									</Typography>
								</Box>

								<Box display="flex" flexDirection="column" alignItems="center">
									<FormButton onClick={handleReportFinished}
												disabled={isButtonsDisabled || !isSelected || !isInputFilled}>
										<StopCircleIcon fontSize="large"/>
									</FormButton>
									<Typography variant="body2" mt={1} sx={sxCaption1}>
										終了報告
									</Typography>
								</Box>
							</Box>
						</Grid>
					</SimpleForm>
				</Create>
			</Box>
			<Grid container spacing={2} justifyContent="center" alignItems="center" sx={{mt: 1, mb: 5}}>
				<Grid item xs={6}>
					<Button
						variant="contained"
						fullWidth
						component={Link}
						to="/"
						style={{fontSize: '1.0rem'}}
						disabled={isButtonsDisabled}
					>
						戻る
					</Button>
				</Grid>
			</Grid>
		</RecordContextProvider>
	);
}
